<template>
  <a-card title="数据列表" :body-style="{ padding: 0 }">
    <div slot="extra">
      <a-button
        v-if="$auth('skills_base_sorts_update')"
        :loading="sortLoading"
        :disabled="!sort"
        type="primary"
        shape="round"
        @click="handleSort"
      >
        <a-icon v-if="!sortLoading" type="rocket" /><span>发布</span>
      </a-button>
    </div>
    <main-table ref="table" @sort="sort = true"></main-table>
  </a-card>
</template>

<script>
import * as skillApi from '@/api/skill'
import MainTable from './components/Table'

export default {
  name: 'SkillManagementBaseSkillList',
  components: {
    MainTable,
  },
  data() {
    return {
      sort: false,
      sortLoading: false,
    }
  },
  mounted() {
    const { $nextTick, initData } = this

    $nextTick(() => {
      this.ready = true
    })

    initData()
  },
  methods: {
    initData() {
      this.$refs.table.initData()
    },
    handleSort() {
      const { $refs, $notification, initData } = this

      let list = $refs.table.list
      list = list.map((v) => ({ id: v.id, sequence: v.sequence }))

      this.sortLoading = true
      skillApi
        .baseSkillSort(list)
        .then(() => {
          this.sort = false
          $notification['success']({ message: '操作成功' })
          initData()
        })
        .finally(() => (this.sortLoading = false))
    },
  },
}
</script>

<style lang="less" scoped>
.option-wrapper {
  display: flex;
  align-items: center;
}
</style>
